import * as actionTypes from './types';
import axios from "axios";

export const fetchConfig = () => dispatch => {
    axios.get('/api/config').then(({data}) => {
        dispatch({
            type: actionTypes.FETCH_CONFIG_SUCCESS,
            payload: data
        })
    }).catch((e) => {
        dispatch({
            type: actionTypes.FETCH_CONFIG_ERROR,
            payload: e
        })
    })
};
