import {lazy} from 'react';

// project imports
import MainLayout from '../layout/MainLayout';
import Loadable from '../ui-component/Loadable';

// dashboard routing
const Home = Loadable(lazy(() => import('../views/Home')));
const Contact = Loadable(lazy(() => import('../views/Contact')));
const Dcma = Loadable(lazy(() => import('../views/Dcma')));
const Privacy = Loadable(lazy(() => import('../views/Privacy')));
const Rules = Loadable(lazy(() => import('../views/Rules')));
const Match = Loadable(lazy(() => import('../views/Match')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout/>,
    children: [
        {
            path: '/',
            element: <Home/>
        },
        {
            path: '/match/:slug/:id',
            element: <Match/>
        },
        {
            path: '/contact',
            element: <Contact/>
        },
        {
            path: '/rules',
            element: <Rules/>
        },
        {
            path: '/dcma',
            element: <Dcma/>
        },
        {
            path: '/privacy',
            element: <Privacy/>
        }
    ]
};

export default MainRoutes;