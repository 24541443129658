import * as actionTypes from './types';
import axios from 'axios';

export const fetchGames = () => dispatch => {
    axios.get('/api/sport/nfl').then(({data}) => {
        dispatch({
            type: actionTypes.FETCH_GAMES_SUCCESS,
            payload: data
        })
    }).catch((e) => {
        dispatch({
            type: actionTypes.FETCH_GAMES_ERROR,
            payload: e
        })
    })
};
