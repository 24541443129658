import {combineReducers} from 'redux';

// reducer import
import homeReducer from './home/reducer';
import matchReducer from './match/reducer';
import configReducer from './configReducer'

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    home: homeReducer,
    match: matchReducer,
    config: configReducer
});

export default reducer;